// LABEL
@import "core";

@include respond-to(base-nomediaquery) {
  // Breaking labels
  @keyframes label-breaking {
    0% {max-width:77%;font-size:var(--label-font-size);}
    40% {max-width:77%;font-size:var(--label-font-size);}
    65% {max-width:0;font-size:var(--label-font-size);color:var(--color-white);}
    66% {max-width:0;font-size:0;color:transparent;}
    91% {max-width:77%;font-size:0;}
    100% {max-width:77%;font-size:0;}
  }

  @keyframes label-breaking2 {
    0% {font-size:0;color:transparent;}
    65% {font-size:0;color:transparent;}
    66% {font-size:var(--label-font-size);color:var(--color-white);}
    100% {font-size:var(--label-font-size);color:var(--color-white);}
  }

  // Inverted breaking labels
  @keyframes label-breaking-inverted {
    0% {max-width:77%;font-size:var(--label-font-size);}
    40% {max-width:77%;font-size:var(--label-font-size);}
    65% {max-width:0;font-size:var(--label-font-size);color:var(--color-midnight);}
    66% {max-width:0;font-size:0;color:transparent;}
    91% {max-width:77%;font-size:0;}
    100% {max-width:77%;font-size:0;}
  }

  @keyframes label-breaking2-inverted {
    0% {font-size:0;color:transparent;}
    65% {font-size:0;color:transparent;}
    66% {font-size:var(--label-font-size);color:var(--color-midnight);}
    100% {font-size:var(--label-font-size);color:var(--color-midnight);}
  }
}

// Because of Firefox bug with using 'color-scheme' inside a :visited link, we use color constants here
@include respond-to(base-inline) {
  :where(.tc_label){--label-font-size:var(--font-12);display:inline-block;font-size:var(--label-font-size);font-weight:var(--font-medium);line-height:var(--spacing-20);height:var(--spacing-20);padding:0 var(--spacing-8);color:var(--color-midnight);overflow:hidden;text-overflow:ellipsis;max-width:77%;white-space:nowrap;text-decoration:none;background-color:var(--color-bright-sand);}
  :where(.tc_label--size-s-m){--label-font-size:var(--font-10-12);line-height:calc(var(--spacing-4-step) + var(--spacing-12));height:calc(var(--spacing-4-step) + var(--spacing-12));padding:0 var(--spacing-4-step);}
  :where(.tc_label--size-m-l){--label-font-size:var(--font-16-20);line-height:var(--spacing-24-step);height:var(--spacing-24-step);padding:0 calc(var(--spacing-4-step) + var(--spacing-4));}

  :where(.tc_label--color-nyheder){background-color:var(--color-bright-peach);}
  :where(.tc_label--color-sport){background-color:var(--color-bright-sky);}
  :where(.tc_label--color-vejr){background-color:var(--color-light-green);}
  :where(.tc_label--color-echo){background-color:var(--color-bright-lavendar);}
  :where(.tc_label--color-live){background:var(--color-live);color:var(--color-white);}
  :where(.tc_label--breaking-true){background:var(--color-midnight);color:var(--color-white);}
  :where(.tc_label--breaking-inverted){background:var(--color-breaking);color:var(--color-midnight);}
}

@include respond-to(base) {
  :where([class*="tc_label--breaking"]){text-overflow:clip;will-change:transform;
    &::after{color:transparent;font-size:0;content:"Breaking";}
  }
  :where(.tc_label--breaking-true){animation:label-breaking 4s linear infinite alternate;
    &::after{animation:label-breaking2 4s linear infinite alternate;}
  }
  :where(.tc_label--breaking-inverted){animation:label-breaking-inverted 4s linear infinite alternate;
    &::after{animation:label-breaking2-inverted 4s linear infinite alternate;}
  }
}

@include respond-to(print) {
  .tc_label{display:inline-flex;align-items:center;text-decoration:none;font-size:var(--font-12);font-weight:var(--font-bold);padding:0 var(--spacing-4);border:var(--spacing-2) solid var(--color-black);animation:none;color:inherit;margin-bottom:var(--spacing-4);
    &::after{animation:none;content:"";}
    & + .tc_label{border-left:0;}
  }
}
